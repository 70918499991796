import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ImageMeta from '../components/ImageMeta'
import ButtonGroupMap from '../components/Buttons/ButtonGroupMap'
import HomeSlide from '../components/HomeSlide'

const Homepage = ({ pageContext, data }) => {
  useEffect(() => {
    const body = document.body
    window.scrollTo(0, 0)

    return () => {
      body.style.overflowY = 'visible'
    }
  }, [])

  const post = data.allUniquePagesJson.nodes[0]

  const featuresSlides = post.accordion.map((slide) => ({
    imageId: slide.imageId,
    imageIdMobile: slide.imageIdMobile,
    blurb: slide.blurb,
    backgroundColor: slide.backgroundColor
  }))

  return (
    <Layout className="main-homepage">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={null}
      />

      {featuresSlides.map((slide, i) => (
        <HomeSlide
          key={i}
          index={i + 1}
          buttonAnchor={
            i === featuresSlides.length - 1 ? 'passion' : `home-slide-${i + 2}`
          }
          text={slide.blurb}
          image={slide.imageId}
          bgColor={slide.backgroundColor}
        />
      ))}

      <div className="omsm__apply-banner" id="passion">
        <div className="omsm__apply-banner--contents">
          <h2>{post.ourMission.header}</h2>
          <p role="heading">{post.ourMission.blurb}</p>
          <h5>{post.ourMission.quote}</h5>
        </div>
      </div>

      <div className="columns oms-success">
        <div className="column is-3"></div>
        <div className="column is-6">
          <ImageMeta
            publicId={post.omsSuccess.gif}
            cloudName="nuvolum"
            width="auto"
            responsive
            className="home-hero__bg-image"
          />
        </div>
        <div className="column success-column is-4">
          <h5>{post.omsSuccess.leftColumn.header}</h5>
          <p
            role="heading"
            dangerouslySetInnerHTML={{
              __html: post.omsSuccess.leftColumn.blurb
            }}
          />
          <ButtonGroupMap buttons={post.omsSuccess.leftColumn.buttons} />
        </div>
        <div className="green-vl is-hidden-touch"></div>
        <div className="column success-column is-4">
          <p
            role="heading"
            dangerouslySetInnerHTML={{
              __html: post.omsSuccess.rightColumn.blurb
            }}
          />
          <ButtonGroupMap buttons={post.omsSuccess.rightColumn.buttons} />
        </div>
        <div className="column is-1"></div>
      </div>

      <div className="columns dit-section">
        <div className="column is-half dit-section-left">
          <ImageMeta
            publicId={post.dentalImplantsToday.imageId}
            cloudName="nuvolum"
            width="auto"
            responsive
          />
          <div className="dit-logo">
            <ImageMeta
              publicId={post.dentalImplantsToday.logo}
              cloudName="nuvolum"
              width="auto"
              responsive
            />
          </div>
        </div>
        <div className="column is-half dit-section-right">
          <p role="heading">{post.dentalImplantsToday.blurb}</p>
          <ButtonGroupMap buttons={post.dentalImplantsToday.buttons} />
        </div>
      </div>
      <div className="dit-hl" />
    </Layout>
  )
}

Homepage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object
}

export const pageQuery = graphql`
  query basicquery {
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        metaTitle
        metaDescription
        accordion {
          imageId
          imageIdMobile
          blurb
          backgroundColor
        }
        ourMission {
          header
          blurb
          quote
        }
        omsSuccess {
          gif
          leftColumn {
            header
            blurb
            buttons {
              button {
                href
                buttonText
                appearance
                destination
              }
            }
          }
          rightColumn {
            header
            blurb
            buttons {
              button {
                href
                buttonText
                appearance
                destination
              }
            }
          }
        }
        dentalImplantsToday {
          imageId
          logo
          blurb
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
        }
      }
    }
  }
`

export default Homepage
