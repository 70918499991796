import React from 'react'
import MarkdownViewer from '@bit/azheng.joshua-tree.markdown-viewer'
import useWindowDimensions from '../hooks/useWindowDimensions'
import ImageMeta from './ImageMeta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'

const HomeSlide = ({ text, image, bgColor, index, buttonAnchor }) => {
  const { width } = useWindowDimensions()
  const scrollToNext = (id) => {
    const el = document.getElementById(id)
    window.scroll({
      left: 0,
      behavior: 'smooth',
      top: el.offsetTop - (width < 768 ? 60 : 115)
    })
  }
  return (
    <div
      className="home-slide"
      style={{ background: bgColor }}
      id={`home-slide-${index}`}
    >
      {buttonAnchor && (
        <div
          role="button"
          tabIndex="0"
          className="home-slide__button"
          onClick={() => scrollToNext(buttonAnchor)}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      )}
      <MarkdownViewer className="home-slide__text" markdown={text} />
      <ImageMeta
        className="home-slide__image"
        publicId={image}
        cloudName="nuvolum"
        width="auto"
        responsive
      />
    </div>
  )
}

export default HomeSlide
